import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  Box,
  Button,
  Container,
  FormGroup,
  LoadingSpinner,
  H1,
  H2,
} from '@allied-solutions/affinity'

import { useFetchBorrower } from '../../../hooks'
import { FullScreenLayout } from '../../../components'

const IncorrectInfo = () => {
  const { handleSubmit } = useFetchBorrower(
    '/enter-reference-id/incorrect-info/confirm-identity/'
  )

  return (
    <FullScreenLayout>
      <Container px={8} role="main">
        <Box mt={['100px', null, 0]} mx="auto" textAlign="center">
          <H1 typeStyle="titleLarge" fontWeight="900">
            Information incorrect?
          </H1>
          <H2 typeStyle="displayMedium" lineHeight="56px" fontWeight="900">
            Check your Reference ID
            <br /> and try again.
          </H2>
          <Formik
            initialValues={{
              refId: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              refId: Yup.string().required(
                'Please enter a valid Reference ID.'
              ),
            })}
          >
            {props => (
              <Form noValidate>
                <Box
                  display={[null, null, 'flex']}
                  alignItems="center"
                  justifyContent="center"
                  mt={18}
                >
                  <FormGroup
                    id="refId"
                    required
                    mr={[null, null, 5]}
                    minWidth="250px"
                  >
                    <FormGroup.Label>Reference ID</FormGroup.Label>
                    <FormGroup.InputGroup>
                      <FormGroup.InputGroup.Input
                        pattern="[0-9]*"
                        placeholder="Retype your Reference ID"
                        css={`
                          max-height: 40px;
                        `}
                      />
                    </FormGroup.InputGroup>
                  </FormGroup>
                  <Button
                    id="IncorrectInfo__Button--submit"
                    type="submit"
                    size="md"
                    rounded
                    disabled={!props.isValid || props.isSubmitting}
                    variant={!props.isValid ? 'red' : 'primary'}
                    alignItems="center"
                    mt={[4, null, 0]}
                    mb={6}
                  >
                    {props.isSubmitting ? (
                      <>
                        <LoadingSpinner mr={2} />
                        Trying again...
                      </>
                    ) : (
                      <>Try Again</>
                    )}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </FullScreenLayout>
  )
}

export default IncorrectInfo
